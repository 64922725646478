@import 'ngx-toastr/toastr';
@import "n27.font";
@import "ron.font";
@import "sketchblock.font";
@import "variables";
@import "titles";
@import "buttons";
@import "cards";
@import "blocks";
@import 'tooltip';
@import "inputs";

body {
  padding: 0;
  margin: 0;
  font-family: 'N27', 'Arial', sans-serif;
  background: var(--zlan-dark);
  background-image: url('/assets/BACKGROUND.jpg');
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: var(--zlan-white);
  overflow-x: hidden;

  @media screen and (max-width: 900px){
    background-position: 50% 50%;
    background-attachment: scroll;
  }
}

.wrapper {
  flex: 1;
  min-height: calc(100vh - var(--header-height) - var(--footer-height));
  position: relative;
  z-index: 1;
}

zlan-icon {
  display: flex;
}

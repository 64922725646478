// dialog bubble
.dialog {
  background: linear-gradient(20deg, var(--zlan-dark) 30%, var(--zlan-light) 100%);
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 20px;
  padding: 20px 20px 40px;
  max-width: 90%;
  box-sizing: border-box;
  position: relative;
  z-index: 1;

  strong {
    color: var(--zlan-green);
  }


  // top right dialog arrow
  &::before {
    content: '';
    position: absolute;
    top: -10px;
    right: 20px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--zlan-light);
    z-index: 0;
  }

  // signature text on the bottom right
  &::after {
    content: '― Le Superviseur';
    position: absolute;
    bottom: 10px;
    right: 20px;
    color: var(--zlan-green);
    font-size: 0.8em;
    font-weight: bold;
  }
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  gap: 1em;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  gap: .5em;
}

:root {
  --zlan-green: #52ff1d;
  --zlan-green-rgb: 82, 255, 29;
  --zlan-white: #eaeaea;
  --zlan-white-rgb: 234, 234, 234;
  --zlan-gray: #cfcfcf;
  --zlan-gray-rgb: 207, 207, 207;
  --zlan-black: #060d12;
  --zlan-black-rgb: 6, 13, 18;
  --zlan-dark: #172933;
  --zlan-dark-rgb: 23, 41, 51;
  --zlan-light: #466872;
  --zlan-light-rgb: 70, 104, 114;

  --header-height: 104px;
  --footer-height: 154px;

  --container-padding: 20px;
}



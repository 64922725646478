@font-face {
  font-family: 'N27';
  src: url('/assets/fonts/N27 Desktop/N27-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'N27';
  src: url('/assets/fonts/N27 Desktop/N27-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'N27';
  src: url('/assets/fonts/N27 Desktop/N27-RegularItalic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'N27';
  src: url('/assets/fonts/N27 Desktop/N27-BoldItalic.otf') format('opentype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'N27';
  src: url('/assets/fonts/N27 Desktop/N27-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'N27';
  src: url('/assets/fonts/N27 Desktop/N27-ThinItalic.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'N27';
  src: url('/assets/fonts/N27 Desktop/N27-ExtraLight.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'N27';
  src: url('/assets/fonts/N27 Desktop/N27-ExtraLightItalic.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'N27';
  src: url('/assets/fonts/N27 Desktop/N27-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'N27';
  src: url('/assets/fonts/N27 Desktop/N27-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'N27';
  src: url('/assets/fonts/N27 Desktop/N27-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'N27';
  src: url('/assets/fonts/N27 Desktop/N27-MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}


.input-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 0;
  background: var(--zlan-dark);
  border: 1px solid var(--zlan-light);
  border-radius: 4px;

  input[type="text"], input[type="email"], input[type="password"] {
    border: 0;
    padding: 0.5em 1em;
    color: var(--zlan-white);
    background: transparent;
    font-size: 1em;
    transition: all 0.3s ease;
/*    padding-block: 1px;
    padding-inline: 0;*/

    &:focus {
      border-color: var(--zlan-primary);
      outline: none;
    }
  }

  button.embeded {
    border: 0;
    padding: 0.5em 15px;
    color: var(--zlan-white);
    background: var(--zlan-dark);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;

    &:not(:last-child) {
      border-right: 1px solid var(--zlan-light);
    }

    &:disabled {
      background: var(--zlan-dark);
      color: var(--zlan-light);
      cursor: not-allowed;
    }

    &:not(:disabled):hover {
      cursor: pointer;
      background: var(--zlan-light);
    }
    &:not(:disabled):active {
      background: var(--zlan-dark);
    }
  }
}

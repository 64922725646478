.zlan-card {
  width: 700px;

  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 100vw;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin-bottom: 1em;

  .zlan-card-header {
    text-align: center;
    transform: translateY(50%);
    font-family: 'RON', sans-serif;
    position: relative;
    text-transform: uppercase;
    color: #53ff1d;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    font-weight: bold;
    white-space: nowrap;
    font-size: 2em;
  }
  .zlan-card-content {
    padding: 20px;
    padding-top: 40px;
    background: #FFF;
    border-radius: 5px;
    background: radial-gradient(circle, #253c45, #0b1821);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);

    p > strong {
      color: var(--zlan-green);
    }
  }
}

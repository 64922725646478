h2 {
  text-transform: uppercase;
  color: var(--zlan-green);
  margin: 0;
}

.subtitle {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: flex-start;
  font-size: .8em;
  opacity: .7;
  white-space: nowrap;

  span {
    flex: 1;
  }
}

h1 {
  font-size: 48px;
}

.with-anchor {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  &:before {
    content: '#ZLAN2024';
    color: var(--zlan-green);
    font-family: SketchBlock, sans-serif;
    font-size: .5em;
  }
}

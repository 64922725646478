.ng-tooltip {
  position: absolute;
  max-width: 300px;
  font-size: 15px;
  text-align: center;
  color: var(--zlan-gray);
  padding: 5px 10px;
  background: var(--zlan-dark);
  border-radius: 4px;
  z-index: 1000;
  opacity: 0;
}
.ng-tooltip:after {
  content: "";
  position: absolute;
  border-style: solid;
}
.ng-tooltip-top:after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: var(--zlan-dark) transparent transparent transparent;
}
.ng-tooltip-bottom:after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: transparent transparent var(--zlan-dark) transparent;
}
.ng-tooltip-left:after {
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent transparent transparent var(--zlan-dark);
}
.ng-tooltip-right:after {
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent var(--zlan-dark) transparent transparent;
}
.ng-tooltip-show {
  opacity: 1;
}
